.header {
  text-align: center;
  margin-top: 20px;
  font-size: 6vw; 
  font-family: 'Comic Sans MS'; 
  vertical-align: middle;
}
.board {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 2px;
  width: 50%;
  height: 50%;
}
.square {
  border: 2px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5vw; 
  font-family: 'Comic Sans MS';
  cursor: pointer;
}
.winner {
  border: 2px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5vw; 
  font-family: 'Comic Sans MS';
  cursor: pointer;
  background-color: red;
}
.status {
  position: absolute;
  top: 75%;
  left: 0%;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  font-size: 6vw; 
  font-family: 'Comic Sans MS'; 
  vertical-align: middle;
}
